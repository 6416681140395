export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default },
    { path: '/upload_excel', component: require('./components/Upload_excel.vue').default },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import('./components/Settings.vue'),
        meta: {
            title: 'Settings'
        },
    },
    {
        path: '/vendors',
        name: 'Vendor',
        component: () => import('./components/Vendor/index.vue'),
        meta: {
            title: 'Vendor'
        },
    },
    {
        path: '/projects',
        name: 'Projects',
        component: () => import('./components/Projects/index.vue'),
        meta: {
            title: 'Projects'
        },
    },
    {
        path: '/po_initiator',
        name: 'PoInitiator',
        component: () => import('./components/Po/po_initiator.vue'),
        meta: {
            title: 'PO'
        },
    },
    {
        path: '/po',
        name: 'Projects',
        component: () => import('./components/Po/index.vue'),
        meta: {
            title: 'PO'
        },
    },
    {
        path: '/invoices',
        name: 'Invoices',
        component: () => import('./components/Invoices/index.vue'),
        meta: {
            title: 'Invoices'
        },
    },
    {
        path: '/invoice/add',
        name: 'InvoiceADd',
        component: () => import('./components/Invoices/AddEdit.vue'),
        meta: {
            title: 'Invoice Add'
        },
    },
    {
        path: '/invoice/edit/:id',
        name: 'InvoiceEdit',
        component: () => import('./components/Invoices/AddEdit.vue'),
        meta: {
            title: 'Invoice Update'
        },
    },
    {
        path: '/payments',
        name: 'Payments',
        component: () => import('./components/Payments/index.vue'),
        meta: {
            title: 'Payments'
        },
    },
    {
        path: '/payment_report',
        name: 'payment_report',
        component: () => import('./components/Report/Payment.vue'),
        meta: {
            title: 'Payment Report'
        },
    },
    {
        path: '/mismatch_report',
        name: 'mismatch_report',
        component: () => import('./components/Report/Invoice.vue'),
        meta: {
            title: 'Invoice Mismatch Report'
        },
    },
    {
        path: '/mail_template',
        name: 'mail_template',
        component: () => import('./components/MailTemplate/index.vue'),
        meta: {
            title: 'Mail Template'
        },
    },
    {
        path: '/system_generated_mail',
        name: 'system_generated_mail',
        component: () => import('./components/MailNotification.vue'),
        meta: {
            title: 'Mail'
        },
    },

    {
        path: '/transports',
        name: 'Transports',
        component: () => import('./components/Transports/index.vue'),
        meta: {
            title: 'Transports'
        },
    },

];
