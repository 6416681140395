
<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header p-2">
                            <ul class="nav nav-pills">
                                <li class="nav-item"> Excel Upload </li>
                            </ul>
                        </div><!-- /.card-header -->
                        <div class="card-body">
                            <div class="tab-content">
                                <!-- Activity Tab -->
                                <div class="tab-pane" id="activity">
                                    <h3 class="text-center">Display User Activity</h3>
                                </div>
                                <!-- Setting Tab -->
                                <div class="tab-pane active show" id="settings">

                                    <a href="upload_formats.xls" target="_blank">Sample Format</a>

                                    <form @submit.prevent="updateInfo" class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="inputName" class="control-label">File Type</label>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="optradio" value="Vendor Upload" v-model="file_type">Vendor Upload
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="optradio" value="PO Upload" v-model="file_type">PO Upload
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="optradio"  value="Invoice Upload" v-model="file_type">Invoice Upload
                                                    </label>
                                                </div>
                                                <div class="form-check disabled">
                                                    <label class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="optradio" value="Payment Upload" v-model="file_type">Payment Upload
                                                    </label>
                                                </div>
                                                <div class="form-check disabled">
                                                    <label class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="optradio" value="TDS Payment" v-model="file_type">TDS Payment Upload
                                                    </label>
                                                </div>
                                                <div class="form-check disabled">
                                                    <label class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="optradio" value="Update Invoice" v-model="file_type">Update Invoice
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="inputName" class="control-label">File</label>
                                                <input type="file" @change="UploadExcelFile" name="Excel_file"
                                                    ref="filematerial" class="form-control"
                                                    :class="{ 'is-invalid': verrors.has('Excel_file') }" v-validate="'required'"
                                                    data-vv-as="Excel file" />
                                                <div v-if="verrors.has('Excel_file')"
                                                    class="help-block invalid-feedback">
                                                    {{ verrors.first('Excel_file') }}</div>

                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-md-12">
                                                <button type="submit" class="btn btn-success">Upload File</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <!-- /.tab-pane -->
                            </div>
                            <!-- /.tab-content -->
                        </div><!-- /.card-body -->
                    </div>
                    <!-- /.nav-tabs-custom -->
                </div>
                <!-- end tabs -->
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            ExcelFile: '',
            file_type: 'Vendor Upload',
        }
    },
    methods: {
        async UploadExcelFile(event) {
            this.ExcelFile = event.target.files[0]
        },
        updateInfo() {
                        this.$validator.validate().then(async (valid) => {
                if (valid) {
                    let pop_loader = this.$loading.show();
            this.$Progress.start();
            let formData = new FormData();
            formData.append('file', this.ExcelFile);
            formData.append('file_type', this.file_type);
            axios.post('api/upload_excel', formData)
                .then((data) => {
                    pop_loader.hide()
                    this.$Progress.finish();
                    if(data){
                    this.ExcelFile='';
                    this.$refs.filematerial.value = null;
                    }
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                })
                .catch((data) => {
                    this.$Progress.fail();
                    pop_loader.hide()
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occurred! Please try again'
                    });
                });

                        }
        });
},

    },

}
</script>
