<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">

                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">User List</h3>

                            <div class="card-tools">

                                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Sl. No</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Created</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(user,x) in users.data" :key="user.id">
                                        <td>{{ users.from + x }}</td>
                                        <td class="text-capitalize">{{ user.name }}</td>
                                        <td>{{ user.email }}</td>
                                        <td>{{ user.created_at | myDate }}</td>
                                        <td>
                                            <a href="javascript:void(0);" @click="editModal(user)" class="btn btn-success btn-sm">
                                                Edit
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination :limit='10' :data="users" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>


            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content ">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Create New User</h5>
                            <h5 class="modal-title" v-show="editmode">Update User's Info</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <!-- <form @submit.prevent="createUser"> -->

                        <form @submit.prevent="editmode ? updateUser() : createUser()">
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input v-model="form.name" type="text" name="name" class="form-control"
                                                :class="{ 'is-invalid': verrors.has('name') }" v-validate="'required'"
                                                data-vv-as="name"  >
                                            <div v-if="verrors.has('name')" class="help-block invalid-feedback"> {{
                                                    verrors.first('name')
                                            }}</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input v-model="form.email" type="text" name="email" class="form-control"
                                                :class="{ 'is-invalid': verrors.has('email') }"
                                                v-validate="'required|email'" data-vv-as="email"  >
                                            <div v-if="verrors.has('email')" class="help-block invalid-feedback"> {{
                                                    verrors.first('email')
                                            }}</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Password</label>
                                            <input v-model="form.password" type="password" name="password"
                                                class="form-control" :class="{ 'is-invalid': verrors.has('password') }"
                                                v-validate="{ required: form.id?false:true }" data-vv-as="password">
                                            <div v-if="verrors.has('password')" class="help-block invalid-feedback"> {{
                                                    verrors.first('password')
                                            }}</div>
                                        </div>
                                    </div>
<!--                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Address</label>
                                            <input v-model="form.address" type="text" name="address"
                                                class="form-control" :class="{ 'is-invalid': verrors.has('address') }"
                                                v-validate="'required'" data-vv-as="address">
                                            <div v-if="verrors.has('address')" class="help-block invalid-feedback"> {{
                                                    verrors.first('address')
                                            }}</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>State</label>
                                            <v-select label="name" :reduce="(option) => option.name" :options="states" placeholder="Enter State ..." v-model="form.state" v-validate="'required'"
                                            :class="{ 'error': verrors.state, 'error': verrors.has('state'), 'haveValue': form.state }"
                                            data-vv-name="state"/>
                                            <div v-if="verrors.has('state')" class="help-block invalid-feedback">{{verrors.first('state')}}</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>City</label>
                                            <v-select label="name" :reduce="(option) => option.name" :options="cities" placeholder="Enter City ..." v-model="form.city" v-validate="'required'"
                                            :class="{ 'error': verrors.city, 'error': verrors.has('city'), 'haveValue': form.city }"
                                            data-vv-name="city"/>
                                            <div v-if="verrors.has('city')" class="help-block invalid-feedback">{{verrors.first('city')}}</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Pin Code</label>
                                            <input v-model="form.zip_code" type="text" name="zip_code"
                                                class="form-control" :class="{ 'is-invalid': verrors.has('zip_code') }"
                                                v-validate="'required|integer|min:6|max:6'" data-vv-as="Pin code">
                                            <div v-if="verrors.has('zip_code')" class="help-block invalid-feedback"> {{
                                                    verrors.first('zip_code')
                                            }}</div>
                                        </div>

                                    </div>
                                 -->

                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            editmode: false,
            users: {},
            states: [],
            cities: [],
            form: new Form({
                id: '',
                name: '',
                type: 'user',
                email: '',
                password: '',
                email_verified_at: '',
                contact_no: '',
                user_name: '',
                address: '',
                state: '',
                city: '',
                zip_code: ''
            })
        }
    },
    methods: {

        getResults(page = 1) {

            this.$Progress.start();

            axios.get('api/user?page=' + page).then(({ data }) => (this.users = data.data));

            this.$Progress.finish();
        },
        updateUser() {
            this.$validator.validateAll().then(async (valid) => {
                if (valid) {
                    this.verrors.clear();
                    this.$Progress.start();
                    // console.log('Editing data');
                    this.form.put('api/user/' + this.form.id)
                        .then((response) => {
                            // success
                            $('#addNew').modal('hide');
                            Toast.fire({
                                icon: 'success',
                                title: response.data.message
                            });
                            this.$Progress.finish();
                            //  Fire.$emit('AfterCreate');

                            this.loadUsers();
                        })
                        .catch(err => {
                            if (err.response && err.response.data) {
                                this.$setErrorsFromResponse(err.response.data);
                            }
                        });
                }
            })
        },
        editModal(user) {
            this.editmode = true;
            this.form.reset();
            this.verrors.clear();
            $('#addNew').modal('show');
            this.form.fill(user);
        },
        newModal() {
            this.editmode = false;
            this.form.reset();
            this.verrors.clear();
            $('#addNew').modal('show');
        },
        deleteUser(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    this.form.delete('api/user/' + id).then(() => {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                        // Fire.$emit('AfterCreate');
                        this.loadUsers();
                    }).catch((data) => {
                        Swal.fire("Failed!", data.message, "warning");
                    });
                }
            })
        },
        loadUsers() {
            this.$Progress.start();

            if (this.$gate.isAdmin()) {
                axios.get("api/user").then(({ data }) => (this.users = data.data));
            }

            this.$Progress.finish();
        },

        createUser() {
            this.$validator.validateAll().then(async (valid) => {
                if (valid) {
                    this.verrors.clear();
                    this.form.post('api/user')
                        .then((response) => {
                            $('#addNew').modal('hide');

                            Toast.fire({
                                icon: 'success',
                                title: response.data.message
                            });

                            this.$Progress.finish();
                            this.loadUsers();

                        })
                        .catch(err => {
                            if (err.response && err.response.data) {
                                this.$setErrorsFromResponse(err.response.data);
                            }
                        });
                }
            })
        }

    },
    mounted() {
        console.log('User Component mounted.')
    },
    created() {
        this.$Progress.start();
        this.loadUsers();
        this.$Progress.finish();
    },
    beforeCreate() {
        axios.get("api/getStates")
            .then((res) => {
                this.states = res.data.data
            })
    },
    watch: {
        'form.state': {
            immediate: true,
            handler(n) {
                if (n) {
                    axios.get("api/getCities", {
                            params: {
                                'state': n
                            }
                        })
                        .then((res) => {
                            this.cities = res.data.data
                        })
                }
            }
        },
    },
}
</script>
